.news-list {
    padding-top: 56px;
    padding-bottom: 88px;
    li {
        a {
            display: block;
            background-color: #f5f5f5;
            padding: 20px 54px;
            overflow: hidden;
            &:hover {
                .img {
                    opacity: .8;
                }
                .time {
                    background-color: $mainGreen;
                }
                h3 {
                    text-indent: .5em;
                    color: $mainGreen;
                }
            }
        }
        .img {
            float: left;
            width: 260px;
            span {
                display: block;
                width: 100%;
                height: 197px;
                position: relative;
            }
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
        .time {
            margin-top: 45px;
            float: right;
            width: 100px;
            height: 100px;
            color: #fff;
            background-color: #555;
            text-align: center;
            font-family: "Arial";
            transition: all .2s ease;
        }
        .date {
            font-size: 24px;
            padding-top: 25px;
        }
        .year {
            font-size: 14px;
        }
        .info {
            margin-left: 320px;
            margin-right: 170px;
            padding-top: 45px;
        }
        h3 {
            font-size: 20px;
            line-height: 28px;
            height: 28px;
            overflow: hidden;
            color: #333;
            margin-bottom: 10px;
            transition: all .5s ease;
        }
        .p {
            font-size: 13px;
            line-height: 25px;
            height: 50px;
            overflow: hidden;
            color: #777;
        }
    }
    li + li {
        margin-top: 20px;
    }
    @media screen and (max-width: 768px) {
        padding-top: px2rem(56px);
        padding-bottom: px2rem(88px);
        li {
            a {
                padding: px2rem(20px);
            }
            .img {
                width: 200px;
                span {
                    height: 0;
                    padding-bottom: (197/260)*100%;
                }
            }
            .info {
                margin-left: 230px;
                padding-top: 20px;
                margin-right: 130px;
            }
            .time {
                margin-top: 20px;
            }
        }
    }
    @media screen and (max-width: 640px) {
        li {
            .img {
                width: 120px;
            }
            .time {
                display: none;
            }
            .info {
                margin-right: 0;
                padding-top: 0;
                margin-left: 140px;
                h3 {
                    font-size: 14px;
                    height: 20px;
                    line-height: 20px;
                    margin-bottom: 5px;
                }
            }
        }
        li + li {
            margin-top: px2rem(20px);
        }
    }
}
.pic-list {
    width: 1272px;
    margin: 0 auto;
    padding-top: 54px;
    padding-bottom: 40px;
    box-sizing: border-box;
    li {
        float: left;
        width: 390px;
        margin: 0 17px 37px;
        box-sizing: border-box;
        a {
            display: block;
            position: relative;
            box-sizing: border-box;
            padding: 40px;
            background-color: #fafaf8;
            transition: all .4s ease;
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;
            &:after {
                position: absolute;
                width: 0;
                height: 2px;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, -50%);
                content: '';
                background: $mainGreen;
                display: block;
                transition: all .4s ease;
            }
            &:hover {
                background-color: darken(#fafaf8, 4%);
                &:after {
                    width: 100%;
                }
            }
        }
        .img {
            border: 1px solid #ededeb;
            width: 100%;
            box-sizing: border-box;
            height: 0;
            padding-bottom: (231/308)*100%;
            margin-bottom: 30px;
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;
        }
        p {
            text-align: center;
            font-size: 18px;
            color: #333;
            line-height: 22px;
            height: 22px;
            overflow: hidden;
        }
    }
    @media screen and (max-width: 1272px) {
        width: 100%;
        padding-top: px2rem(54px);
        padding-bottom: px2rem(40px);
        padding-right: px2rem(10px);
        padding-left: px2rem(10px);
        li {
            width: 33.33%;
            margin-right: 0;
            margin-left: 0;
            padding-right: px2rem(10px);
            padding-left: px2rem(10px);
        }
    }
    @media screen and (max-width: 800px) {
        li {
            a {
                padding: px2rem(20px);
            }
            .img {
                margin-bottom: px2rem(30px);
            }
            p {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        li {
            width: 50%;
            margin-bottom: px2rem(40px);
        }
    }
}
.recruitment-classify {
    background-color: #505050;
    padding: 15px 40px;
    line-height: 26px;
    font-size: 16px;
    color: #fff;
    margin-top: 60px;
    margin-bottom: 20px;
    box-sizing: border-box;
    overflow: hidden;
    dt {
        float: left;
    }
    dd {
        margin-left: 50px;
        font-size: 0;
        a {
            display: inline-block;
            vertical-align: top;
            color: #fff;
            width: 33.33%;
            box-sizing: border-box;
            padding-right: 10px;
            font-size: 16px;
            &.on,
            &:hover {
                color: $mainGreen;
            }
        }
    }
    @media screen and (max-width: 750px) {
        font-size: 12px;
        padding: 15px px2rem(40px);
        margin-top: px2rem(60px);
        line-height: 30px;
        dd {
            a {
                width: auto;
                margin-right: 0;
                padding-right: px2rem(30px);
            }
        }
    }
}
.talent-recruitment {
    position: relative;
    z-index: 22;
    padding-bottom: 62px;
    @media screen and (max-width: 750px) {
        padding-bottom: px2rem(60px);
    }
    .talent-recruitment-list {
        width: 100%;
        box-sizing: border-box;
        .active {
            .talent-title {
                .icon-rotate {
                    span {
                        transform: rotate(0deg);
                    }
                }
            }
        }
        > li:first-child {
            margin-top: 0;
            .nr {
                display: block;
            }
        }
        > li {
            border: 1px solid #d7d7d7;
            background: #fff;
            margin-top: 16px;
            .talent-title {
                position: relative;
                width: 100%;
                height: 53px;
                line-height: 16px;
                font-size: 0;
                cursor: pointer;
                overflow: hidden;
                li {
                    position: relative;
                    top: 50%;
                    transform: translate(0, -50%);
                    display: inline-block;
                    vertical-align: top;
                    text-align: center;
                    font-size: 14px;
                    height: 16px;
                    color: #333;
                    overflow: hidden;
                    width: 175px;
                    padding: 0 6px;
                    box-sizing: border-box;
                }
                .name {
                    width: 286px;
                }
                .company {
                    width: 280px;
                }
                @media screen and (max-width: 1150px) {
                    .dn1150 {
                        display: none;
                    }
                    li, .name {
                        width: 23%;
                    }
                }
                .icon-rotate {
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    width: 72px;
                    transform: translate(0, 0%);
                    line-height: 53px;
                    span {
                        transition: all .4s ease;
                        display: inline-block;
                        vertical-align: middle;
                        width: 34px;
                        height: 34px;
                        border: 1px solid #e6e6e6;
                        border-radius: 50%;
                        background-image: url(../images/icon-21.png);
                        background-position: center center;
                        background-repeat: no-repeat;
                        transform: rotate(180deg);
                    }
                }
                @media screen and (max-width: 800px) {
                    padding-right: px2rem(60px);
                    box-sizing: border-box;
                    .department,
                    .number,
                    .add,
                    .company {
                        display: none;
                    }
                    .name {
                        width: 100%;
                        box-sizing: border-box;
                        text-align: left;
                        padding: 0 px2rem(20px);
                    }
                    .icon-rotate {
                        padding: 0;
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 30px;
                        height: 100%;
                        span {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
            .nr {
                display: none;
                border-top: 1px solid #d7d7d7;
                background: #fff;
                padding: 80px 0 84px;
                > div {
                    font-size: 0;
                    position: relative;
                    .mailbox {
                        position: absolute;
                        left: 100px;
                        bottom: -60px;
                        a {
                            font-size: 13px;
                            color: #333;
                            height: 40px;
                            line-height: 40px;
                            display: inline-block;
                            vertical-align: top;
                            padding: 0 35px;
                            border: 1px solid #020202;
                            position: relative;
                            transition: all 0.3s ease;
                            &:hover {
                                background: #5b5b5b;
                                border-color: #5b5b5b;
                                color: #fff;
                            }
                        }
                    }
                    h1 {
                        font-size: 24px;
                        color: #444;
                        line-height: 28px;
                        margin-bottom: 18px;
                    }
                    .p {
                        font-size: 14px;
                        color: #555;
                        line-height: 30px;
                    }
                }
                .left {
                    display: inline-block;
                    vertical-align: top;
                    width: (565/1200)*100%;
                    box-sizing: border-box;
                    padding-left: 100px;
                    padding-right: 52px;
                }
                .right {
                    display: inline-block;
                    vertical-align: top;
                    width: (624/1200)*100%;
                    box-sizing: border-box;
                    padding-left: 100px;
                    padding-right: 52px;
                }
                .mobile-title {
                    display: none;
                    font-size: 14px;
                    color: #333;
                    line-height: 24px;
                    margin-bottom: 10px;
                    p {
                        font-weight: bold;
                    }
                }
                @media screen and (max-width: 800px) {
                    padding: px2rem(40px) px2rem(20px);
                    > div {
                        &:after {
                            display: none;
                        }
                        h1 {
                            margin-bottom: px2rem(20px);
                            font-size: 18px;
                        }
                        .mailbox {
                            margin-top: px2rem(20px);
                            position: static;
                        }
                    }
                    .left {
                        padding: 0;
                        width: 100%;
                    }
                    .right {
                        margin-top: px2rem(40px);
                        padding: 0;
                        width: 100%;
                    }
                    .mobile-title {
                        display: block;
                    }
                }
            }
        }
    }
}
.classify-con {
    .hd {
        text-align: center;
        font-size: 0;
        padding-top: 30px;
        li {
            display: inline-block;
            vertical-align: top;
            width: 150px;
            height: 50px;
            line-height: 50px;
            background-color: #fafaf8;
            color: #333;
            cursor: pointer;
            font-size: 16px;
            overflow: hidden;
            &.on {
                color: #fff;
                background-color: $mainGreen;
            }
        }
        @media screen and (max-width: 750px) {
            padding-right: px2rem(20px);
            padding-left: px2rem(20px);
            li {
                width: 33.33%;
                line-height: 40px;
                height: 40px;
                font-size: 14px;
            }
        }
    }
    .bd {
        .pic-list {
            display: none;
            &:first-child {
                display: block;
            }
        }
    }
}