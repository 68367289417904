@charset 'utf-8';

/* CSS Document */

//SCSS全局变量
$mainGreen: #28b06b;
// 默认移动端设计稿宽度
$baseDevice: 750;
// 前端切图移动端默认正常显示尺寸，默认为设计稿的一半
$device: $baseDevice / 2;
// 默认html font-size
$baseFontSize: 100px;
// scss function
@function calc-percent($target, $context) {
    @return $target/$context * 100%;
}
@function px2rem($px, $base-font-size: $baseDevice / $device * $baseFontSize) {
    @if (unitless($px)) {
        @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels for you";
        @return px2rem($px + 0px); // That may fail.
    } @else if (unit($px)==rem) {
        @return $px;
    }
    @return ($px / $base-font-size) * 1rem;
}
//透明
@mixin touming($o: 70) {
    opacity: $o/100;
    -webkit-opacity: $o/100;
    filter: alpha(opacity=$o);
}
@mixin transition($time: .2s) {
    -webkit-transition: $time ease all;
    -moz-transition: $time ease all;
    transition: $time ease all;
}
@mixin articleReset($fontsize: 14px, $lineHieght: 30px) {
    font-size: $fontsize;
    p {
        margin-bottom: $lineHieght/2;
    }
    img {
        max-width: 100%;
        height: auto !important;
    }
    ul {
        list-style: disc;
        li {
            list-style: disc;
        }
    }
    ol {
        list-style: decimal;
        li {
            list-style: decimal;
        }
    }
}
/* reset.css V1.6  Start*/

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ul,
ol,
li,
form,
button,
input,
textarea,
th,
td {
    font-weight: normal;
    margin: 0;
    padding: 0;
}
body,
button,
input,
select,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
i {
    font: normal 12px BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
ul,
ol,
li {
    list-style: none;
}
a {
    text-decoration: none;
    color: #333;
}
a:hover {
    text-decoration: none;
}
img {
    vertical-align: top;
    border: 0;
}
button,
input,
textarea {
    font-size: 100%;
    vertical-align: middle;
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
div,
a {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
html {
    font-size: $baseFontSize;
}
@media screen and (min-width: 320px) {
    html {
        font-size: (320/$device)*$baseFontSize;
    }
}
@media screen and (min-width: 360px) {
    html {
        font-size: (360/$device)*$baseFontSize;
    }
}
@media screen and (min-width: 375px) {
    html {
        font-size: (375/$device)*$baseFontSize;
    }
}
@media screen and (min-width: 480px) {
    html {
        font-size: (480/$device)*$baseFontSize;
    }
}
@media screen and (min-width: 640px) {
    html {
        font-size: (640/$device)*$baseFontSize;
    }
}
@media screen and (min-width: 750px) {
    html {
        font-size: (750/$device)*$baseFontSize;
    }
}
.clearfloat:after {
    font-size: 0;
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: ' ';
}
.clearfloat {
    *zoom: 1;
}
.clearboth {
    clear: both;
}
.wp1350 {
    width: 1350px;
    margin: 0 auto;
    @media screen and (max-width: 1390px) {
        width: auto;
        margin: 0 20px;
    }
    @media screen and (max-width: 750px) {
        margin: 0 px2rem(20px);
    }
}
.wp1280 {
    width: 1280px;
    margin: 0 auto;
    @media screen and (max-width: 1320px) {
        width: auto;
        margin: 0 20px;
    }
    @media screen and (max-width: 750px) {
        margin: 0 px2rem(20px);
    }
}
.checkBrowser {
    font-size: 14px;
    line-height: 40px;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 50%;
    width: 700px;
    height: 40px;
    margin-left: -360px;
    padding: 0 10px;
    color: #fff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #e6212a;
    a {
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        text-align: center;
        color: #333;
        border-radius: 50%;
        background-color: #fff;
    }
}
/* reset.css V1.6  End */

// page结构以及CSS样式
// *******************
// ********************
// ********************
// HTML结构
// <div class="paged">
//  <a href="#">首页</a>
//  <a class="">上一页</a>
//  <span class="current">1</span>
//  <a href="#">2</a>
//  <a class="" href="#">下一页</a>
//  <a href="#">尾页</a>
// </div>
// ********************
// ********************
// ********************
// CSS样式
.paged { text-align: center; }
.paged a, .paged .p_info, .paged .current { display: inline-block; zoom: 1; *display: inline;
    margin: 0px 3px; padding: 10px 15px; font-size: 15px; text-align: center; background: #f5f5f5; color: #000; }
.paged .current, .paged a:hover { background: $mainGreen; color: #fff; }
@media screen and (max-width: 750px) {
    .paged {
        a, span, .current {
            display: none;
        }
        .prev, .next {
            display: inline-block;
        }
    }
}
.header {
    position: relative;
    background-color: #fff;
    z-index: 15;
    &-top {
        background-color: #333;
        text-align: right;
        font-size: 12px;
        line-height: 36px;
        color: #fff;
        .item {
            display: inline-block;
            vertical-align: top;
            a {
                color: #fff;
                padding: 0 10px;
                &:hover {
                    color: $mainGreen;
                }
            }
        }
    }
    h1 {
        float: left;
        a {
            display: block;
            height: 100%;
            img {
                height: 100%;
            }
        }
    }
    &-search {
        float: right;
        position: relative;
        height: 80px;
        width: 50px;
        line-height: 80px;
        z-index: 50;
        &:hover {
            .header-search-box {
                display: block;
            }
        }
        .btn {
            display: inline-block;
            vertical-align: middle;
            width: 32px;
            height: 32px;
            background-color: $mainGreen;
            background-image: url(../images/icon_1.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            border-radius: 100%;
            cursor: pointer;
        }
        &-box {
            position: absolute;
            width: 220px;
            background-color: #fff;
            border-radius: 10px;
            overflow: hidden;
            right: 8px;
            top: 80px;
            border: 1px solid #e5e5e5;
            display: none;
            input[type="text"] {
                width: 160px;
                border-radius: 10px;
                height: 36px;
                line-height: 36px;
                appearance: none;
                background: none;
                font-size: 14px;
                color: #666;
                box-sizing: border-box;
                padding: 0 10px;
                display: block;
                float: left;
                border: none;
            }
            input[type="submit"] {
                float: left;
                width: 60px;
                border-radius: 10px;
                height: 36px;
                line-height: 36px;
                appearance: none;
                font-size: 14px;
                display: block;
                background: $mainGreen;
                cursor: pointer;
                color: #fff;
                border: none;
            }
        }
    }
    &-sub {
        position: absolute;
        width: 100%;
        border-top: 1px solid #e1e1e1;
        box-sizing: border-box;
        overflow: hidden;
        left: 0;
        top: 80px;
        background-color: #fff;
        height: 0;
        > div {
            padding: 40px 0;
        }
        .img {
            float: left;
        }
        &-box {
            float: right;
            margin-right: 60px;
            ul {
                float: left;
                min-height: 1px;
                li {
                    margin-bottom: 12px;
                    padding-left: 28px;
                    a {
                        font-size: 12px;
                        color: #979797;
                        &:hover {
                            color: $mainGreen;
                        }
                    }
                }
            }
        }
    }
    &-menu-btn {
        display: none;
        width: px2rem(80px);
        height: px2rem(80px);
        float: right;
        background: url(../images/icon_8.png) center no-repeat;
        background-size: auto 38%;
    }
    @media screen and (max-width: 1100px) {
        &-menu-btn {
            display: block;
        }
        &-search,
        &-sub,
        &-top {
            display: none;
        }
    }
    @media screen and (max-width: 750px) {
        h1,
        &-menu-btn {
            height: px2rem(110px);
        }
    }
}
.nav {
    float: right;
    margin-right: 10px;
    @media screen and (max-width: 1100px) {
        display: none;
    }
    li {
        float: left;
        > a {
            display: block;
            height: 80px;
            line-height: 80px;
            padding: 0 28px;
            font-size: 16px;
            color: #333;
            transition: all .2s ease;
            &:hover {
                background-color: $mainGreen;
                color: #fff;
            }
        }
        &.on {
            > a {
                background-color: $mainGreen;
                color: #fff;
                &:before {
                    content: '●';
                    margin-right: 5px;
                }
            }
        }
    }
}
.fast-link {
    float: left;
    &-con {
        padding: 45px 0 35px;
        background-color: #222;
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
    &-ewm {
        float: right;
        padding: 10px;
        background-color: #fff;
        border-radius: 4px;
        overflow: hidden;
        text-align: center;
        font-size: 14px;
        color: #5e5050;
        width: 140px;
        p {
            line-height: 30px;
        }
        img {
            display: block;
            width: 100%;
        }
    }
    dl {
        float: left;
        width: 130px;
        margin-left: 44px;
        &:first-child {
            margin-left: 0;
        }
        dt {
            border-bottom: 1px solid #4e4e4e;
            padding-bottom: 14px;
            margin-bottom: 18px;
            a {
                font-size: 15px;
                color: #fff;
                &:hover {
                    color: $mainGreen;
                }
            }
        }
        dd {
            margin-bottom: 6px;
            line-height: 18px;
            font-size: 12px;
            a {
                color: #bcbcbc;
                &:hover {
                    color: $mainGreen;
                }
            }
        }
    }
}
.footer {
    padding: 25px 0;
    background-color: #1f1f1f;
    line-height: 30px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    a {
        color: #fff;
        &:hover {
            color: $mainGreen;
        }
    }
    span {
        margin: 0 5px;
    }
    @media screen and (max-width: 1024px) {
        .screen {
            display: none;
        }
    }
    @media screen and (max-width: 480px) {
        padding: px2rem(30px) 0;
        span {
            display: block;
        }
    }
}
.sidebar {
    position: fixed;
    width: 48px;
    border-bottom-left-radius: 8px;
    right: 0;
    top: 50%;
    z-index: 666;
    transform: translateY(-50%);
    background-color: $mainGreen;
    @media screen and (max-width: 1024px) {
        display: none;
    }
    li {
        position: relative;
        &:last-child {
            > * {
                border-bottom: none;
            }
        }
    }
    span, a {
        display: inline-block;
        margin: 0 10px;
        font-size: 12px;
        color: #fff;
        padding: 10px 0;
        border-bottom: 1px solid #94d8b5;
    }
    &-ewm {
        position: absolute;
        right: 50px;
        top: 0;
        width: 140px;
        padding: 10px;
        background: $mainGreen;
        display: none;
        img {
            display: block;
            width: 100%;
        }
    }
    .show-ewm {
        &:hover {
            .sidebar-ewm {
                display: block;
            }
        }
    }
    .go-top {
        padding-top: 20px;
        position: relative;
        cursor: pointer;
        &:before {
            position: absolute;
            width: 0;
            height: 0;
            display: block;
            content: '';
            border-right: 8px solid transparent;
            border-bottom: 8px solid #fff;
            border-left: 8px solid transparent;
            left: 50%;
            margin-left: -8px;
            top: 10px;
        }
    }
}
.nav-mobile {
    display: none;
    position: fixed;
    width: 100%;
    //top: px2rem(102px);
    left: 0;
    bottom: 0;
    z-index: 101;
    background-color: #f5f5f5;
    box-sizing: border-box;
    overflow: hidden;
    &-wp {
        position: relative;
    }
    &-search {
        padding: px2rem(70px);
        border-bottom: 1px solid #d7d7d7;
        &-box {
            display: flex;
            border: 1px solid #d7d7d7;
            border-radius: px2rem(10px);
            background-color: #fff;
            overflow: hidden;
            input[type="text"] {
                flex: 1;
                padding: 0 px2rem(15px);
                font-size: px2rem(20px);
                color: #999;
                border: none;
                background: none;
                appearance: none;
                height: px2rem(58px);
                line-height: px2rem(58px);
            }
            input[type="submit"] {
                width: px2rem(60px);
                height: px2rem(58px);
                text-align: center;
                background: url(../images/icon_1.png) no-repeat center;
                border: none;
                appearance: none;
                line-height: px2rem(58px);
                font-size: px2rem(28px);
                color: #fff;
            }
        }
    }
    ul {
        border-top: 1px solid #fff;
        padding: px2rem(20px);
    }
    &-title {
        position: relative;
        border-bottom: 1px dashed #c4c4c4;
        padding: 0 px2rem(20px);
        > a {
            position: relative;
            display: inline-block;
            vertical-align: top;
            line-height: px2rem(80px);
            font-size: px2rem(30px);
            color: #333;
            z-index: 2;
            backface-visibility: hidden;
        }
    }
    &-child-btn {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: right;
        line-height: px2rem(80px);
        font-size: px2rem(30px);
        font-weight: bold;
        z-index: 1;
        color: #848484;
        span {
            display: inline-block;
            margin-right: px2rem(40px);
        }
        i {
            display: inline-block;
            font-style: normal;
            //transform: rotate(90deg);
            margin-right: px2rem(40px);
            height: px2rem(80px);
            width: px2rem(60px);
            background: url(../images/arrow_3.png) no-repeat center;
            background-size: auto px2rem(10px);
        }
        &.open {
            i {
                transform: rotate(180deg);
            }
        }
    }
    .sub {
        display: none;
        //padding: 0 px2rem(40px);
        li {
            border-bottom: 1px dashed #c4c4c4;
            a {
                display: block;
                line-height: px2rem(80px);
                font-size: px2rem(30px);
                height: px2rem(80px);
                color: #999;
                overflow: hidden;
                padding-left: px2rem(20px);
                position: relative;
                &:before {
                    display: inline-block;
                    vertical-align: top;
                    margin-right: px2rem(10px);
                    color: #999;
                    content: '-';
                }
            }
        }
    }
}
.inside-banner {
    width: 100%;
    height: 400px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    &-mobile {
        width: 100%;
        height: 0;
        padding-bottom: (520/750)*100%;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        display: none;
    }
    @media screen and (max-width: 750px) {
        display: none;
        &-mobile {
            display: block;
        }
    }
}
.inside-menu {
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    text-align: center;
    &-item {
        display: inline-block;
        vertical-align: top;
        padding: 0 12px;
        font-size: 15px;
        color: #666;
        margin: 0 30px;
        line-height: 64px;
        position: relative;
        &:hover {
            color: $mainGreen;
        }
        &.on {
            color: #666;
            &:before {
                position: absolute;
                width: 100%;
                height: 2px;
                background: $mainGreen;
                content: '';
                left: 0;
                bottom: 0;
                z-index: 5;
            }
        }
    }
    &-sub {
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
        text-align: center;
        a {
            display: inline-block;
            vertical-align: top;
            font-size: 15px;
            color: #666;
            margin: 0 40px;
            line-height: 52px;
            position: relative;
            &:hover,
            &.on {
                color: $mainGreen;
            }
        }
    }
    @media screen and (max-width: 1100px) {
        display: none;
        &-sub {
            display: none;
        }
    }
}
.bread {
    display: none;
    padding: 0 px2rem(20px);
    border-bottom: 1px solid #e5e5e5;
    line-height: 60px;
    font-size: 14px;
    color: #666;
    position: relative;
    z-index: 10;
    box-sizing: border-box;
    a {
        color: #666;
        &:hover {
            color: $mainGreen;
        }
    }
    &-btn {
        position: absolute;
        width: 60px;
        height: 60px;
        background: url(../images/arrow_3.png) center no-repeat;
        top: 0;
        right: 0;
        z-index: 5;
        transition: all .4s ease;
        &.open {
            transform: rotate(180deg);
        }
    }
    &-list {
        position: absolute;
        width: 100%;
        left: 0;
        top: 61px;
        background-color: #fff;
        display: none;
        li {
            border-bottom: 1px solid #e5e5e5;
            a {
                display: block;
                padding: 0 px2rem(40px);
                font-size: 14px;
                color: #666;
                line-height: 42px;
                box-sizing: border-box;
            }
        }
    }
    @media screen and (max-width: 1100px) {
        display: block;
    }
}
.inside-con {
    padding-top: 75px;
    padding-bottom: 100px;
    @media screen and (max-width: 750px) {
        padding-top: px2rem(75px);
        padding-bottom: px2rem(100px);
    }
}
.inside-menu-slide {
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    text-align: center;
    &-swiper {
        width: auto;
        margin: 0 auto;
        display: inline-block;
        vertical-align: top;
        max-width: 100%;
    }
    .swiper-slide {
        display: inline-block;
        vertical-align: top;
        padding: 0 12px;
        font-size: 15px;
        color: #666;
        margin: 0 30px;
        line-height: 64px;
        position: relative;
        cursor: pointer;
        width: auto;
        @media screen and (max-width: 750px) {
            font-size: 12px;
            line-height: 40px;
            margin: 0 15px;
        }
        &:hover {
            color: $mainGreen;
        }
        &.on {
            color: #666;
            &:before {
                position: absolute;
                width: 100%;
                height: 2px;
                background: $mainGreen;
                content: '';
                left: 0;
                bottom: 0;
                z-index: 5;
            }
        }
    }
}