.index-banner {
    width: 100%;
    height: 400px;
    .swiper-slide {
        a {
            display: block;
            width: 100%;
            height: 400px;
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    .swiper-pagination {
        bottom: 23px;
        &-bullet {
            width: 24px;
            height: 5px;
            border-radius: 2px;
            background-color: #545456;
            opacity: 1;
            transition: all .4s ease;
            &-active {
                width: 44px;
                background-color: $mainGreen;
            }
        }
    }
    &-mobile {
        width: 100%;
        display: none;
        .swiper-slide {
            a {
                display: block;
                width: 100%;
                height: 0;
                padding-bottom: (520/750)*100%;
                background-position: center top;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
        .swiper-pagination {
            bottom: 12px;
            &-bullet {
                width: 12px;
                height: 3px;
                border-radius: 2px;
                background-color: #545456;
                opacity: 1;
                transition: all .4s ease;
                &-active {
                    width: 22px;
                    background-color: $mainGreen;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        display: none;
        &-mobile {
            display: block;
        }
    }
}
.index-title {
    text-align: center;
    padding-bottom: 12px;
    position: relative;
    &:after {
        position: absolute;
        width: 42px;
        height: 2px;
        background: $mainGreen;
        bottom: 0;
        left: 50%;
        margin-left: -21px;
        content: '';
    }
    .cn {
        font-size: 28px;
        color: #000;
    }
    .en {
        font-size: 16px;
        color: #b0b0b0;
    }
    @media screen and (max-width: 750px) {
        .cn {
            font-size: 19px;
        }
        .en {
            font-size: 10px;
        }
    }
}
.index-headline {
    padding: 65px 0 95px;
    background-color: #f6f6f6;
    @media screen and (max-width: 750px) {
        padding: px2rem(65px) 0 px2rem(95px);
    }
    &-con {
        position: relative;
        background-color: #fff;
        margin-top: 35px;
        @media screen and (max-width: 750px) {
            margin-top: px2rem(40px);
        }
    }
    &-list {
        margin-right: 330px;
        @media screen and (max-width: 800px) {
            margin-right: 0;
        }
        li {
            float: left;
            width: 50%;
            height: 180px;
            box-sizing: border-box;
            border-left: 1px solid #f7f7f7;
            border-bottom: 1px solid #f7f7f7;
            &:nth-child(2n+1) {
                border-left: none;
            }
            a {
                display: block;
                padding: 48px;
                &:hover {
                    .date {
                        background-color: $mainGreen;
                        border-color: $mainGreen;
                        color: #fff;
                    }
                }
            }
        }
        .date {
            float: left;
            width: 96px;
            height: 96px;
            border: 1px solid #a9dfc4;
            border-radius: 4px;
            text-align: center;
            display: table;
            vertical-align: middle;
            color: #666;
            margin-right: 25px;
            transition: all .4s ease;
        }
        .td {
            vertical-align: middle;
            display: table-cell;
        }
        .day {
            font-size: 34px;
        }
        .year {
            font-size: 13px;
        }
        .info {
            padding-top: 6px;
            h4 {
                font-size: 16px;
                color: #333;
                height: 20px;
                line-height: 20px;
                overflow: hidden;
                margin-bottom: 20px;
            }
        }
        .p {
            font-size: 14px;
            color: #999;
            line-height: 20px;
            height: 40px;
            overflow: hidden;
        }
        @media screen and (max-width: 750px) {
            li {
                width: 100%;
                height: auto;
                a {
                    padding: px2rem(32px);
                }
            }
        }
        @media screen and (max-width: 480px) {
            .date {
                width: px2rem(130px);
                height: px2rem(130px);
                margin-right: px2rem(22px);
            }
            .day {
                font-size: px2rem(46px);
            }
            .year {
                font-size: px2rem(22px);
            }
            .info {
                padding-top: 0;
                h4 {
                    font-size: px2rem(28px);
                    margin-bottom: px2rem(8px);
                }
            }
            .p {
                font-size: px2rem(22px);
                line-height: px2rem(36px);
                height: px2rem(72px);
            }
        }
    }
}
.index-video {
    &-btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 330px;
        bottom: 0;
        cursor: pointer;
        .btn {
            position: absolute;
            top: 0;
            width: 100%;
            bottom: 30px;
            &:hover {
                opacity: .8;
            }
        }
        .link {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            line-height: 30px;
            text-align: right;
            padding: 0 10px;
            box-sizing: border-box;
            font-size: 14px;
            a {
                color: #333;
                &:hover {
                    color: $mainGreen;
                }
            }
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
        @media screen and (max-width: 800px) {
            position: relative;
            top: auto;
            right: auto;
            width: 100%;
            bottom: auto;
            padding-bottom: (359/330)*100%;
            height: 0;
            img {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    &-con {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .6);
        z-index: 997;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        &.show {
            opacity: 1;
            visibility: visible;
        }
    }
    &-close {
        position: absolute;
        width: 50px;
        height: 50px;
        color: #fff;
        background-color: $mainGreen;
        font-size: 26px;
        font-weight: bold;
        text-align: center;
        line-height: 50px;
        top: 0;
        right: 0;
        transition: all .4s ease;
        cursor: pointer;
    }
    &-box {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 90%;
        width: 1280px;
        padding-bottom: (9/16)*100%;
        transform: translate(-50%, -45%);
        height: 0;
        opacity: 0;
        transition: transform .4s ease;
        &.show {
            transform: translate(-50%, -50%);
            opacity: 1;
        }
        video,
        .mejs-container,
        .mejs-overlay {
            position: absolute;
            width: 100% !important;
            height: 100% !important;
        }
    }
}
.index-industry {
    position: relative;
    overflow: hidden;
    height: 670px;
    @media screen and (max-width: 800px) {
        height: auto;
    }
    &-title {
        width: 774px;
        position: absolute;
        left: 50%;
        margin-left: -100px;
        top: 65px;
        z-index: 6;
        @media screen and (max-width: 800px) {
            position: relative;
            width: 100%;
            margin-left: 0;
            left: auto;
            top: auto;
            padding-top: px2rem(66px);
        }
    }
    &-pagination {
        position: absolute;
        width: 774px;
        left: 50%;
        margin-left: -100px;
        top: 185px;
        bottom: auto;
        z-index: 89;
        text-align: center;
        .swiper-pagination-bullet {
            width: 24px;
            height: 5px;
            border-radius: 2px;
            background-color: #545456;
            opacity: 1;
            transition: all .4s ease;
            margin: 0 5px;
            cursor: pointer;
            display: inline-block;
            vertical-align: top;
            &-active {
                width: 44px;
                background-color: $mainGreen;
            }
        }
        @media screen and (max-width: 800px) {
            position: relative;
            width: 100%;
            margin-left: 0;
            left: auto;
            top: auto;
            padding-top: px2rem(40px);
            .swiper-pagination-bullet {
                width: 12px;
                height: 3px;
                &-active {
                    width: 22px;
                }
            }
        }
    }
    &-swiper {
        .swiper-slide {
            padding-top: 220px;
            background-color: #fff;
            height: 670px;
            box-sizing: border-box;
            @media screen and (max-width: 800px) {
                padding-top: px2rem(30px);
                height: auto;
            }
            .item {
                &:nth-child(2n+1) {
                    background-color: #eee;
                    .p {
                        &:before {
                            background-color: #e5e5e5;
                        }
                    }
                }
                &.on {
                    .img {
                        display: block;
                    }
                    .info {
                        background-color: $mainGreen;
                        .p {
                            &:before {
                                background: #94d8b5;
                            }
                            h4,
                            .d {
                                color: #fff;
                            }
                        }
                        .wrapper {
                            &:before {
                                display: block;
                            }
                        }
                    }
                    .back {
                        display: inline-block;
                    }
                    .front {
                        display: none;
                    }
                    @media screen and (max-width: 800px) {
                        .img {
                            display: none;
                        }
                        .info {
                            background: none;
                            .p {
                                &:before {
                                    background: #e5e5e5;
                                }
                                h4 {
                                    color: #4e4e4e;
                                }
                                .d {
                                    color: #9a9a9a;
                                }
                            }
                            .wrapper {
                                &:before {
                                    display: none;
                                }
                            }
                        }
                        .back {
                            display: none;
                        }
                        .front {
                            display: inline-block;
                        }
                    }
                }
            }
            .img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 50%;
                margin-right: 100px;
                background-position: center right;
                background-repeat: no-repeat;
                background-size: cover;
                display: none;
                z-index: 5;
            }
            .info {
                position: relative;
                height: 150px;
                display: block;
                box-sizing: border-box;
                padding: 35px 0;
                transition: all .2s ease;
                @media screen and (max-width: 480px) {
                    padding: px2rem(60px) 0;
                    height: auto;
                }
            }
            .wrapper {
                position: absolute;
                left: 50%;
                margin-left: -100px;
                box-sizing: border-box;
                padding-left: 40px;
                transition: all .4s ease;
                @media screen and (max-width: 800px) {
                    left: 0;
                    margin-left: 0;
                    padding-left: 0;
                    position: relative;
                }
                &:hover {
                    padding-left: 0;
                }
                &:before {
                    position: absolute;
                    width: 0;
                    height: 0;
                    content: '';
                    border-top: 15px solid transparent;
                    border-right: 15px solid $mainGreen;
                    border-bottom: 15px solid transparent;
                    left: -15px;
                    top: 50%;
                    margin-top: -15px;
                    display: none;
                    z-index: 6;
                }
            }
            .icon {
                width: 144px;
                height: 66px;
                text-align: center;
                float: left;
                padding-top: 12px;
                img {
                    vertical-align: middle;
                }
                @media screen and (max-width: 480px) {
                    width: px2rem(182px);
                    img {
                        width: px2rem(100px);
                        height: px2rem(100px);
                    }
                }
            }
            .p {
                padding-left: 40px;
                position: relative;
                width: 500px;
                overflow: hidden;
                @media screen and (max-width: 1270px) {
                    width: auto;
                }
                &:before {
                    width: 1px;
                    height: 66px;
                    top: 50%;
                    left: 0;
                    margin-top: -33px;
                    background-color: #ebebeb;
                    content: '';
                    display: block;
                    position: absolute;
                    transition: background-color .2s ease;
                }
                h4 {
                    font-size: 24px;
                    color: #4e4e4e;
                    line-height: 28px;
                    height: 28px;
                    overflow: hidden;
                    margin-bottom: 10px;
                }
                .d {
                    font-size: 13px;
                    color: #9a9a9a;
                    line-height: 26px;
                    height: 52px;
                    overflow: hidden;
                }
                @media screen and (max-width: 480px) {
                    padding-left: px2rem(40px);
                    &:before {
                        height: px2rem(100px);
                        margin-top: px2rem(-50px);
                    }
                    h4 {
                        font-size: px2rem(28px);
                        margin-bottom: 0;
                    }
                    .d {
                        font-size: px2rem(22px);
                        line-height: px2rem(36px);
                        height: px2rem(108px);
                    }
                }
            }
            .front {
                display: inline-block;
            }
            .back {
                display: none;
            }
        }
    }
}
.index-picture {
    padding: 60px 0 50px;
    background-color: #f7f7f7;
    @media screen and (max-width: 750px) {
        padding-top: px2rem(60px);
        padding-bottom: px2rem(50px);
    }
    &-list {
        padding-top: 40px;
        width: 1364px;
        margin: 0 auto;
        @media screen and (max-width: 1364px) {
            width: 100%;
            padding-right: px2rem(10px);
            padding-left: px2rem(10px);
            box-sizing: border-box;
            padding-top: px2rem(40px);
        }
        li {
            float: left;
            margin: 0 8px;
            width: 325px;
            @media screen and (max-width: 1364px) {
                width: 25%;
                margin: 0;
                padding-right: px2rem(10px);
                padding-left: px2rem(10px);
                box-sizing: border-box;
            }
            @media screen and (max-width: 800px) {
                width: 50%;
                margin-bottom: px2rem(20px);
            }
            a {
                display: block;
                width: 100%;
                &:hover {
                    h3 {
                        color: $mainGreen;
                    }
                }
            }
        }
        .img {
            width: 100%;
            padding-bottom: (247/325)*100%;
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: 20px;
            @media screen and (max-width: 750px) {
                margin-bottom: 10px;
            }
        }
        h3 {
            font-size: 16px;
            color: #333;
            height: 28px;
            line-height: 28px;
            overflow: hidden;
            transition: color .2s ease;
        }
        .p {
            font-size: 12px;
            color: #a4a4a4;
            line-height: 20px;
            height: 40px;
            overflow: hidden;
        }
        @media screen and (max-width: 750px) {
            h3 {
                font-size: 14px;
            }
            .p {
                font-size: 10px;
            }
        }
    }
}
.index-news {
    padding-top: 45px;
    background-color: #fff;
    @media screen and (max-width: 750px) {
        padding-top: px2rem(44px);
    }
    &-box {
        margin-top: 35px;
        background-color: #f7f7f7;
    }
    &-swiper {
        padding: 50px 0;
        .swiper-slide {
            a {
                display: block;
                text-align: center;
                &:hover {
                    h4 {
                        color: $mainGreen;
                    }
                }
            }
            .date {
                padding-bottom: 18px;
                font-size: 46px;
                color: #000;
                position: relative;
                margin-bottom: 25px;
                &:after {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 54px;
                    height: 1px;
                    background-color: #b9b9b9;
                    margin-left: -27px;
                    content: '';
                }
            }
            h4 {
                font-size: 16px;
                color: #333;
                height: 26px;
                line-height: 26px;
                overflow: hidden;
                text-align: left;
                max-width: 270px;
                margin: 0 auto 15px;
            }
            .p {
                font-size: 14px;
                line-height: 20px;
                color: #7e7e7e;
                height: 60px;
                overflow: hidden;
                max-width: 270px;
                margin: 0 auto;
                text-align: left;
            }
        }
        .swiper-button-prev {
            z-index: 6;
            width: 20px;
            height: 37px;
            background: url(../images/arrow_1.png) center no-repeat;
            background-size: 100% 100%;
            pointer-events: auto;
        }
        .swiper-button-next {
            z-index: 6;
            width: 20px;
            height: 37px;
            background: url(../images/arrow_2.png) center no-repeat;
            background-size: 100% 100%;
            pointer-events: auto;
        }
        @media screen and (max-width: 750px) {
            padding: px2rem(50px) 0;
            .swiper-slide {
                .date {
                    font-size: px2rem(46px);
                }
            }
        }
    }
}