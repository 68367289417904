.about-history-box {
    position: relative;
    overflow: hidden;
    margin-top: 70px;
    @media screen and (max-width: 750px) {
        margin-top: px2rem(70px);
    }
    .bg-1 {
        position: absolute;
        width: px2rem(313px);
        height: px2rem(240px);
        top: 0;
        left: 50%;
        margin-left: px2rem(-330px);
        z-index: 1;
        background: url(../images/img_5.png) center no-repeat;
        background-size: 100% 100%;
    }
    .bg-2 {
        position: absolute;
        width: px2rem(374px);
        height: px2rem(374px);
        top: 0;
        left: 50%;
        margin-left: px2rem(30px);
        z-index: 1;
        background: url(../images/img_6.png) center no-repeat;
        background-size: 100% 100%;
    }
}
.about-history {
    position: relative;
    z-index: 2;
    min-height: px2rem(374px);
    padding-bottom: px2rem(20px);
    .img {
        position: absolute;
        display: block;
        width: px2rem(215px);
        height: px2rem(215px);
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .line {
        position: absolute;
        width: 3px;
        top: px2rem(100px);
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        background: #c7e2d6;
        z-index: 3;
    }
    .dot {
        position: absolute;
        padding: 5px;
        border-radius: 50%;
        border: 1px dashed $mainGreen;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 10px;
        height: 10px;
        z-index: 3;
        span {
            display: inline-block;
            vertical-align: top;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $mainGreen;
        }
    }
    ul {
        position: relative;
        padding-top: px2rem(290px);
        z-index: 4;
        li {
            .info {
                width: 50%;
                position: relative;
                box-sizing: border-box;
                &:before {
                    position: absolute;
                    display: block;
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: $mainGreen;
                    top: 7px;
                    z-index: 6;
                }
                &:after {
                    position: absolute;
                    display: block;
                    width: px2rem(64px);
                    border-top: 1px dashed #999;
                    content: '';
                    top: 12px;
                    z-index: 5;
                }
                h5 {
                    position: relative;
                    font-size: 19px;
                    font-weight: bold;
                    color: #000;
                    margin-bottom: 10px;
                }
                .p {
                    display: inline-block;
                    vertical-align: top;
                    font-size: 15px;
                    line-height: 26px;
                    color: #434343;
                    width: 320px;
                    max-width: 100%;
                }
            }
            &:nth-child(even) {
                .info {
                    float: left;
                    text-align: right;
                    padding-right: px2rem(75px);
                    &:before,
                    &:after {
                        right: -5px;
                    }
                }
            }
            &:nth-child(odd) {
                .info {
                    float: right;
                    padding-left: px2rem(75px);
                    &:before,
                    &:after {
                        left: -5px;
                    }
                }
            }
        }
    }
}
.inside-box {
    background-color: #fff;
    box-shadow: 0 0 38px rgba(0, 0, 0, .1);
    border-top: 2px solid $mainGreen;
    width: 1240px;
    margin: 0 auto;
    &.slide-box {
        .news-info {
            display: none;
            &:first-child {
                display: block;
            }
        }
    }
    @media screen and (max-width: 1240px) {
        width: 100%;
    }
}
.news-info {
    padding: px2rem(60px) 0 px2rem(100px);
    margin: 0 auto;
    width: 980px;
    max-width: 100%;
    box-sizing: border-box;
    &-top {
        padding-bottom: px2rem(40px);
        border-bottom: 1px solid #dadce2;
        text-align: center;
        h2 {
            font-size: 28px;
            color: #000;
            margin-bottom: px2rem(20px);
        }
        &-tips {
            span {
                display: inline-block;
                vertical-align: top;
                margin: 0 px2rem(12px);
                font-size: 12px;
                color: #5b5b5b;
                line-height: 13px;
                &:before {
                    content: '';
                    display: inline-block;
                    vertical-align: top;
                    height: 13px;
                    width: 18px;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: auto 100%;
                    margin-right: 5px;
                }
            }
            .time {
                &:before {
                    background-image: url(../images/icon_14.png);
                }
            }
            .view {
                &:before {
                    background-image: url(../images/icon_15.png);
                }
            }
        }
    }
    &-article {
        padding-top: px2rem(30px);
        @include articleReset(15px, 30px);
        color: #444;
        line-height: 28px;
        margin-bottom: px2rem(40px);
    }
    &-video {
        position: relative;
        width: 100%;
        padding-bottom: (9/16)*100%;
        margin-bottom: 30px;
        .video-box {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            video,
            .mejs-container,
            .mejs-overlay {
                position: absolute;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
    &-share {
        margin-bottom: px2rem(55px);
        text-align: center;
        .bdsharebuttonbox {
            a {
                display: inline-block;
                float: none;
                vertical-align: top;
            }
        }
    }
    @media screen and (max-width: 980px) {
        padding-left: px2rem(20px);
        padding-right: px2rem(20px);
        &-top {
            h2 {
                font-size: 18px;
            }
        }
    }
}
.article-page {
    li {
        float: left;
        width: 33.33%;
        box-sizing: border-box;
        &:nth-child(1) {
            padding-right: px2rem(16px);
        }
        &:nth-child(2) {
            padding: 0 px2rem(8px);
        }
        &:nth-child(3) {
            padding-left: px2rem(16px);
        }
        a {
            display: block;
            padding: 0 px2rem(30px);
            height: px2rem(50px);
            line-height: px2rem(50px);
            position: relative;
            text-align: center;
            color: #fff;
            font-size: 13px;
            background: $mainGreen;
            transition: all .2s ease;
            overflow: hidden;
            &:hover {
                background: lighten($mainGreen, 5%);
            }
            &:after {
                position: absolute;
                width: 0;
                height: 0;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 7px solid #fff;
                content: '';
                display: block;
                top: 50%;
                right: px2rem(15px);
                transform: translateY(-50%);
            }
        }
    }
}
.article-con {
    padding-top: 54px;
    width: 1180px;
    margin: 0 auto;
    @media screen and (max-width: 1180px) {
        width: 100%;
        box-sizing: border-box;
        padding: px2rem(54px) px2rem(20px) 0;
    }
    .it-top {
        position: relative;
        overflow: hidden;
        margin-bottom: 60px;
        .word-box {
            position: relative;
            margin-top: 22px;
            background-color: #f4f4f4;
            width: 500px;
            padding: 70px 64px;
            box-shadow: 0 0 5px #efefef;
            z-index: 5;
            h4 {
                font-size: 24px;
                margin-bottom: 15px;
                color: #333;
            }
            .p {
                font-size: 14px;
                line-height: 38px;
                color: #777;
            }
        }
        .img {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
            img {
                display: block;
                max-width: 100%;
            }
        }
        @media screen and (max-width: 750px) {
            margin-bottom: px2rem(60px);
            .word-box {
                margin-top: 0;
                width: 100%;
                box-sizing: border-box;
                padding: px2rem(70px) px2rem(64px);
                h4 {
                    font-size: 18px;
                }
            }
            .img {
                position: static;
            }
        }
    }
    .article {
        line-height: 38px;
        color: #777;
        @include articleReset(14px, 38px);
    }
}